<template>
  <base-page
      :title="$t('companyPermissionManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findCompanyPermissionList"
        :defaultParams="params"
        :columns="columns"
        :headers="{product_version: 'NORMAL'}"
        v-model="tableRow"
    >
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('companyPermissionManage.name'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        needFullAddress: 'YES',
      },
      columns: [
        // lang: 企业名称
        {
          label: this.$t('companyPermissionManage.name'),
          prop: 'name',
        },
        // lang: 所属行业
        {
          label: this.$t('companyPermissionManage.industry'),
          prop: 'industry',
          callback(row) {
            return row.industry ? row.industry.value : _that.$t('companyPermissionManage.unknown')
          },
        },
        // lang: 联系人
        {
          label: this.$t('companyPermissionManage.contacts'),
          prop: 'contacts',
        },
        // lang: 联系方式
        {
          label: this.$t('companyPermissionManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang: 联系邮箱
        {
          label: this.$t('companyPermissionManage.email'),
          prop: 'email',
        },
        // lang: 终端访问并发数(个)
        {
          label: this.$t('aiCompanyPermissionManage.terminalConcurrentNum'),
          prop: 'terminalConcurrentNum',
          callback(row) {
            if(!row.companyPermission) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission && row.companyPermission.terminalConcurrentNum === -1) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission
                && row.companyPermission.terminalConcurrentNum !==-1) {
              return row.companyPermission.terminalConcurrentNum
            }
            return _that.$t('companyPermissionManage.unlimited')
          },
        },
        // lang: 算法模型数量(个)
        {
          label: this.$t('aiCompanyPermissionManage.algorithmModelNum'),
          prop: 'algorithmModelNum',
          callback(row) {
            if(!row.companyPermission) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission && row.companyPermission.algorithmModelNum === -1) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission
                && row.companyPermission.algorithmModelNum !==-1) {
              return row.companyPermission.algorithmModelNum
            }
            return _that.$t('companyPermissionManage.unlimited')
          },
        },
        // lang: AI应用数量(个)
        {
          label: this.$t('aiCompanyPermissionManage.aiAppNum'),
          prop: 'aiAppNum',
          callback(row) {
            if(!row.companyPermission) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission && row.companyPermission.aiAppNum === -1) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission
                && row.companyPermission.aiAppNum !==-1) {
              return row.companyPermission.aiAppNum
            }
            return _that.$t('companyPermissionManage.unlimited')
          },
        },
      ],
      // 表单页
      showDetail: false,
    }
  },
  mounted() {
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.addressId) formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'permissionSetting':
          this.showDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>
